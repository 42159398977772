<template>
    <div>
        <DataTable :value="resul.internal" :paginator="false" stripedRows class="p-datatable-customers p-datatable-sm"
            dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters"  v-if="cas=='Internal'">
			<Column field="id" :header="$t('N°')" headerStyle="width: 5%">
				<template #body="slotProps">
					{{slotProps.data.ord}}
				</template>
			</Column>
			<Column field="compliance" :header="$t('Controls')">
				<template #body="slotProps">
					{{slotProps.data['compliance_'+i18n.locale()]}} 
				</template>
			</Column>
			<Column field="ans" :header="$t('Items')" headerStyle="width: 7%">
				<template #body="slotProps">
					{{slotProps.data.ans}}
				</template>
			</Column>
			<Column field="compliance" :header="$t('Max. Score')" headerStyle="width: 10%">
				<template #body="slotProps">
					{{slotProps.data.maxt}}
				</template>
			</Column>
			<Column field="compliance" :header="$t('Weight')+' (%)'" headerStyle="width: 10%">
				<template #body="slotProps">
					{{slotProps.data.weight}}%
				</template>
			</Column>
			<Column field="sum" :header="$t('Score')" headerStyle="width: 10%">
				<template #body="slotProps">
					{{slotProps.data.sum}} 
				</template>
			</Column>
			<Column field="pts" :header="$t('Weighted Risk Score')" headerStyle="width: 10%">
				<template #body="slotProps">
					{{parseFloat(slotProps.data.def).toFixed(2)}}%
				</template>
			</Column>
			<Column field="porc" :header="$t('Score')" headerStyle="width: 10%">
				<template #body="slotProps">
					<Tag :style="'width: 100%; height: 30px; background-color: #'+Scale(parseFloat(slotProps.data.porc))['color']">
						{{parseFloat(slotProps.data.porc).toFixed(0)}}
						{{Scale(parseFloat(slotProps.data.porc))[i18n.locale()]}}
					</Tag>
				</template>
			</Column>
		</DataTable>
        <DataTable :value="resul.internal" :paginator="false" stripedRows class="p-datatable-customers p-datatable-sm"
            dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters"  v-else-if="cas=='External'">
			<Column field="id" :header="$t('N°')" headerStyle="width: 5%">
				<template #body="slotProps">
					{{slotProps.data.ord}}
				</template>
			</Column>
			<Column field="compliance" :header="$t('Controls')">
				<template #body="slotProps">
					{{slotProps.data['compliance_'+i18n.locale()]}} 
				</template>
			</Column>
			<Column field="ans" :header="$t('Items')" headerStyle="width: 7%">
				<template #body="slotProps">
					{{slotProps.data.ans}}
				</template>
			</Column>
			<Column field="compliance" :header="$t('Max. Score')" headerStyle="width: 10%">
				<template #body="slotProps">
					{{slotProps.data.maxt}}
				</template>
			</Column>
			<Column field="compliance" :header="$t('Weight')+' (%)'" headerStyle="width: 10%">
				<template #body="slotProps">
					{{slotProps.data.weight}}%
				</template>
			</Column>
			<Column field="sum" :header="$t('Score')" headerStyle="width: 10%">
				<template #body="slotProps">
					{{resul.external[slotProps.index].sum}}
				</template>
			</Column>
			<Column field="pts" :header="$t('Weighted Risk Score')" headerStyle="width: 10%">
				<template #body="slotProps">
					{{parseFloat(resul.external[slotProps.index].def).toFixed(2)}}%			
				</template>
			</Column>
			<Column field="porc" :header="$t('Score')" headerStyle="width: 10%">
				<template #body="slotProps">
					<Tag :style="'width: 100%; height: 30px; background-color: #'+Scale(parseFloat(resul.external[slotProps.index].porc))['color']">
						{{parseFloat(resul.external[slotProps.index].porc).toFixed(0)}}
						{{Scale(parseFloat(resul.external[slotProps.index].porc))[i18n.locale()]}}
					</Tag>
				</template>
			</Column>
		</DataTable>
		<DataTable :value="resul.internal" :paginator="false" stripedRows class="p-datatable-customers p-datatable-sm"
            dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters"  v-else>
			<Column field="id" :header="$t('N°')" headerStyle="width: 5%">
				<template #body="slotProps">
					{{slotProps.data.ord}}
				</template>
			</Column>
			<Column field="compliance" :header="$t('Controls')">
				<template #body="slotProps">
					{{slotProps.data['compliance_'+i18n.locale()]}} 
				</template>
			</Column>
			<Column field="ans" :header="$t('Items')" headerStyle="width: 7%">
				<template #body="slotProps">
					{{slotProps.data.ans}}
				</template>
			</Column>
			<Column field="compliance" :header="$t('Max. Score')" headerStyle="width: 7%">
				<template #body="slotProps">
					{{slotProps.data.maxt}}
				</template>
			</Column>
			<Column field="compliance" :header="$t('Weight')+' (%)'" headerStyle="width: 7%">
				<template #body="slotProps">
					{{slotProps.data.weight}}%
				</template>
			</Column>
			<Column field="sum" :header="$t('Internal Score')" headerStyle="width: 7%">
				<template #body="slotProps">
					{{slotProps.data.sum}} 
				</template>
			</Column>
			<Column field="pts" :header="$t('Weighted Risk Score')" headerStyle="width: 7%">
				<template #body="slotProps">
					{{parseFloat(slotProps.data.def).toFixed(2)}}%
				</template>
			</Column>
			<Column field="porc" :header="$t('Score')" headerStyle="width: 10%">
				<template #body="slotProps">
					<Tag :style="'width: 100%; height: 30px; background-color: #'+Scale(parseFloat(slotProps.data.porc))['color']">
						{{parseFloat(slotProps.data.porc).toFixed(0)}}
						{{Scale(parseFloat(slotProps.data.porc))[i18n.locale()]}}
					</Tag>
				</template>
			</Column>
			<Column field="sum" :header="$t('External Score')" headerStyle="width: 7%">
				<template #body="slotProps">
					{{resul.external[slotProps.index].sum}}
				</template>
			</Column>
			<Column field="pts" :header="$t('Weighted Risk Score')" headerStyle="width: 7%">
				<template #body="slotProps">
					{{parseFloat(resul.external[slotProps.index].def).toFixed(2)}}%
				</template>
			</Column>
			<Column field="porc" :header="$t('Score')" headerStyle="width: 10%">
				<template #body="slotProps">
					<Tag :style="'width: 100%; height: 30px; background-color: #'+Scale(parseFloat(resul.external[slotProps.index].porc))['color']">
						{{parseFloat(resul.external[slotProps.index].porc).toFixed(0)}}
						{{Scale(parseFloat(resul.external[slotProps.index].porc))[i18n.locale()]}}
					</Tag>
				</template>
			</Column>
		</DataTable>
    </div>
</template>

<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		props: {
            result: {
				type: Object,
				default: null,
			},
            scl: {
				type: Object,
				default: null,
			},
            cas: {
				type: Object,
				default: null,
			},
		},
		data() {
			return {
				i18n: null,
                scale: this.scl,
				resul: this.result,
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
		},
		mounted() {
			
		},
		methods: {
            Scale(valor){
				return this.scale.filter(val => parseFloat(val.min)<= parseFloat(valor) && parseFloat(val.max)>= parseFloat(valor))[0];
			},
		}
	}
</script>
<style scoped lang="scss">
.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
        font-family: 'Rajdhani', sans-serif;
        font-size: calc(0.7rem + 1vw);
        font-weight: 600;
        color: #2b3b80;
    }

	::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>